export const status = Object.freeze({
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
});
export const selectBooleanType: SelectOptionType[] = [
  { text: "true", value: "true" },
  { text: "false", value: "false" },
];

export const goalStyleByStatus : Record<string,string> = {
  completed : "goal-container-table-compeleted",
  pending : "goal-pending",
  rejected : "goal-rejected",
  active : "goal-active",
  inactive : "goal-container-table-disabled",
}

export const goalStatus : Record<string,string> = Object.freeze({
  COMPLETED : "completed",
  PENDING : "pending",
  REJECTED : "rejected",
  ACTIVE : "active",
  INACTIVE : "inactive",
  TAG_TYPE:"userGoalStatus"
})

export const questionFilterTagTypes : Record<string,string> = Object.freeze({
  QuestionDifficultyLevel: "QuestionDifficultyLevel",
  QuestionPlatformType: "QuestionPlatformType",
  QuestionUsageCategory: "QuestionUsageCategory",
  SubTopic: "SubTopic",
  Topic:"Topic",
})

export const questionPlatformTypeTagKeys = Object.freeze({
  FILLUP:"fillup",
  CODEBLOCK:"codeblock",
  LEETCODE:"leetcode",
  SINGLECHOICE: "singleChoice",
  MULTICHOICE: "multipleChoice",
  GITHUBNONDSA: "githubNonDsa",
  GITHUBDSA: "githubDsa",
});

export const noFoundComponentMessages = {
  userGoalNotFound : "User Goal List Not Found",
  goalsNotFound : "Goals Not Found",
  socialLinkNotFound : "No Links Found",
}

export const NOT_AVAILABLE = "N/A";

export const routeUrl = Object.freeze({
  USER_GOAL_RESPONSE: "/userGoalResponse",
})

export const stringConstants = Object.freeze({
  default: "default"
})