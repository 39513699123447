import React, { useState } from "react";
import "./userPaymentCard.scss";
import { Button } from "../../components/button/button";
import { useTranslation } from "react-i18next";
import { useAdmin } from "../../redux/actions/admin";
import { readFileAsDataURL } from "../../utils/readFileAsDataURL";
import { status } from "../../utils/constants";

const UserPaymentCard: React.FC<UserPaymentCardProps> = ({
  payment,
  setIsStatusChanges,
}) => {
  const [paymentReceipt, setPaymentReceipt] = useState<File | null>(null);
  const { _id = "", image, user } = payment;
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const openImagePreview = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsImagePreviewOpen(true);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
    setIsImagePreviewOpen(false);
  };

  const handleOnClick = async (
    action: (paymentId: string) => Promise<string | boolean | undefined>
  ) => {
    setIsLoading(true);
    await action(_id);
    setIsLoading(false);
  };
  const { updateUserPayment } = useAdmin();
  const handleApprove = async (paymentId: string) => {
    if (paymentReceipt) {
      const paymentReceiptUrl = await readFileAsDataURL(paymentReceipt);

      const inputData: UpdateUserPaymentInput = {
        paymentId,
        image: paymentReceiptUrl,
        status: status.APPROVED,
      };
      const response = await updateUserPayment(inputData);
      setIsStatusChanges((currentStatus) => !currentStatus);
      return Boolean(response) && "Approved";
    }
  };

  const handleReject = async (paymentId: string) => {
    const inputData: UpdateUserPaymentInput = {
      paymentId,
      status: status.REJECTED,
    };
    const response = await updateUserPayment(inputData);
    setIsStatusChanges((currentStatus) => !currentStatus);
    return Boolean(response) && "Rejected";
  };

  return (
    <div
      className={`user-payment-card ${payment?.status?.paymentStatus?.tagKey}`}
    >
      {payment?.status?.paymentStatus?.tagKey && (
        <div className={`status-tag ${payment?.status?.paymentStatus?.tagKey}`}>
          {payment?.status?.paymentStatus?.tagName?.toUpperCase()}
        </div>
      )}
      <p className="payment-card-text">
        {t("Payment ID:")} {_id}
      </p>
      <p className="payment-card-text">
        {"User Name:"} {user.name}
      </p>
      <p className="payment-card-text">
        {t("User Email: ")}
        {user.email}
      </p>
      <img
        src={image.secureUrl}
        alt="Fee Slip"
        className="fee-slip-image"
        onClick={() => openImagePreview(image.secureUrl)}
      />
      {
        <>
          <div className="file-input-wrapper">
            <input
              type="file"
              accept="image/*"
              className="file-input"
              onChange={(e) => setPaymentReceipt(e.target.files?.[0] ?? null)}
            />
            <label className="file-input-label">
              {paymentReceipt ? paymentReceipt.name : "Select Receipt"}
            </label>
          </div>
          <div className="action-buttons">
            <Button
              className="approve-button"
              onClick={() => {
                handleOnClick(handleApprove);
              }}
              text={t("Approve")}
              isDisabled={!paymentReceipt}
              isLoading={Boolean(paymentReceipt) && isLoading}
              key={payment._id}
            />

            {!paymentReceipt && (
              <Button
                className="reject-button"
                onClick={() => {
                  handleOnClick(handleReject);
                }}
                text={t("Reject")}
                isLoading={isLoading}
                key={payment._id}
              />
            )}
          </div>
        </>
      }
      {isImagePreviewOpen && (
        <div className="image-preview-modal" onClick={closeImagePreview}>
          <img
            className="preview-image"
            src={selectedImage || ""}
            alt="Image Preview"
          />
        </div>
      )}
    </div>
  );
};

export default UserPaymentCard;
