import React, { useState } from 'react'
import "./MobileViewHeadNav.scss";
import WebMastersLogo from '../../../../icons/webMastersLogo';
import ThreeLineNav from '../../../../icons/threelineNan';
import CrossIconNew from '../../../../icons/crossIconNew';
import ApplyNowButton from '../../ApplyNowButton/ApplyNowButton';
import { Modal } from "../../../../../components/index";
import { Login } from "../../../../../components/Login/login";


interface NavRefsa {
    coursesRef: React.RefObject<HTMLDivElement>;
    aboutUsRef: React.RefObject<HTMLDivElement>;
    pricingRef: React.RefObject<HTMLDivElement>;
    faqRef: React.RefObject<HTMLDivElement>;
}

interface mobileViewHeadNavProps {
    isActiveNav: number;
    NavBarOptions: any[];
    handleNavClick: any;
}


export const MobileViewHeadNav: React.FC<mobileViewHeadNavProps> = ({ isActiveNav, NavBarOptions, handleNavClick }) => {
    const [isActiveNavButton, setIsActiveNavButton] = useState<boolean>(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);


    return (
        <div className='mobileViewHeadNav'>
            {
                isLoginModalOpen && (<Modal> <Login closeModal={() => setIsLoginModalOpen(false)} /> </Modal>)
            }
            <div className="mobile-headNavBar-box">
                <div className="mobile-webmaster-logo">
                    <span className='logo-wrapper'><WebMastersLogo isDarkMode={true} height='64px' width='64px' /> </span>
                    </div>
                <div className='mobile-apply-button-container'>
                    <ApplyNowButton />
                </div>
                <div className='nav-button' onClick={() => setIsActiveNavButton(!isActiveNavButton)}>
                    <div className={`nav-icon ${isActiveNavButton ? "nav-icon-cross-button" : ""}`}>
                        {
                            isActiveNavButton ? <CrossIconNew /> : <ThreeLineNav />
                        }
                    </div>

                </div>

                <div className={`nav-option-container ${isActiveNavButton ? "nav-option-container-active" : ""}`}>
                    {
                        NavBarOptions.map((navItem, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`mobile-nav-buttons ${isActiveNav === index && "mobile-active-nav"}`}
                                    onClick={() => {
                                        handleNavClick(index, navItem.ref)
                                        setIsActiveNavButton(!isActiveNavButton)
                                    }}
                                >
                                    {navItem.label}
                                </div>
                            )
                        })
                    }
                    <div className="mobile-login-button"
                        onClick={() => {
                            setIsLoginModalOpen(true)
                            setIsActiveNavButton(!isActiveNavButton)
                        }}
                    >
                        Login
                    </div>
                </div>
            </div>

        </div>
    )
}
