import React from "react";
import "./adminPage.scss";
import AllUserPaymentsList from "../allUsersPaymentsList/AllUsersPaymentsList";
import { CreateUserGoals } from "../createUserGoals/createUserGoals";
import { CreateQuestion } from "../createQuestion/createQuestion";
import { AllUsersGoalStatus } from "../allUsersGoalStatus/allUsersGoalStatus";


const AdminPanel: React.FC = () => {
  return <>
  <CreateUserGoals/>
  <CreateQuestion/>
  <AllUserPaymentsList/>
  <AllUsersGoalStatus/>
  </>
};

export default AdminPanel;
