import React, { useEffect, useState } from "react";
import {
  AvatarIcon,
  DashboardIcon,
  QuestionIcon,
  ScheduleIcon,
  VideoIcon,
  NameIcon,
  PaymentIcon,
  LeaderBoard,
  EditIcon,
  GoalsIcon,
  Internet,
} from "./icons/index";
import { useTranslation } from "react-i18next";
import { useUser } from "./redux/actions/userAction";
import { useDispatch } from "react-redux";
import { actions } from "./redux/slices/user/userSlice";
import { useNavigate } from "react-router-dom";
import { deleteCookie } from "./utils";
import { apolloClient } from "./graphql/apolloClient/apolloClient";

export const useAppData = (): UseAppDataReturnType => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const { user, getUserData } = useUser();
  const { isLoggedIn, userData } = user || {};
  const { name, profileImage, batchCode } = userData || {};
  const { secureUrl } = profileImage || {};
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const monorepoPaths = {
    "/": true,
    "/register": true,
    "/privacy": true,
    "/privacy/concerns/whatsapp": true,
  };

  const logOut = () => {
    deleteCookie(process.env.REACT_APP_JWT_SECRET_KEY || "");
    dispatch(actions.setIsLoggedIn(false));
    apolloClient.resetStore();
    navigate("/");
  };
  const socialOptions = Object.entries(userData?.socialLinks ?? {}).map(([socialPlatform, link]) => (
    {

    text: socialPlatform,
    image: <Internet /> ,
    url: `sociallink/${socialPlatform}`,
  }));
  
  const sidebarData: SidebarProps = {
    profile: {
      text: name || t("profile"),
      image: secureUrl ? (
        secureUrl
      ) : name ? (
        <NameIcon width="40px" height="40px" name={name} />
      ) : (
        <AvatarIcon isDarkMode={true} />
      ),
      url: "/profile",
      moreInfo: { batchCode },
    },
    admin: user.isAdmin && [
      {
        text: t("admin_panel"),
        image: <EditIcon height="25" width="25"/>,
        url: "/admin_panel"
      },
    ],
    optionsAtFirst: [
      // {
      //   text: t("search"),
      //   image: <SearchIcon isDarkMode={true} />,
      // },
    ],
    options: [
      {
        text: t("dashboard"),
        image: <DashboardIcon isDarkMode={true} />,
        url: "/dashboard",
      },
      {
        text: t("schedule"),
        image: <ScheduleIcon isDarkMode={true} />,
        url: "/schedule",
      },
      {
        text: t("questions"),
        image: <QuestionIcon isDarkMode={true} />,
        url: "/questions",
      },
      {
        text: t("videos"),
        image: <VideoIcon isDarkMode={true} />,
        url: "/videos",
      },
      {
        text: t("payments"),
        image: <PaymentIcon isDarkMode={true} />,
        url: "/userPayment",
      },
      {
        text: t("Leaderboard"),
        image: <LeaderBoard />,
        url: "/leaderboard",
      },
      {
        text: t("goals"),
        image: <GoalsIcon isDarkMode={false} />,
        url: "/goals",
      },
      
    ],
    socialLinksOption: socialOptions,
    optionAtLast: {
      text: t(isLoggedIn ? "logout" : "login"),
      onClick: () => (isLoggedIn ? logOut() : setIsLoginModalOpen(true)),
    },
  };  
  useEffect(() => {
    isLoggedIn && getUserData();
  }, [isLoggedIn]);
  return {
    sidebarData,
    monorepoPaths,
    isLoginModalOpen,
    setIsLoginModalOpen,
    isLoggedIn,
    user: user,
  };
};
