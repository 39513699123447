import React, { useEffect, useState } from "react";
import "./HeadNavBar.scss";
import ApplyNowButton from "../ApplyNowButton/ApplyNowButton";
import WebMastersLogo from "../../../icons/webMastersLogo";
import { MobileViewHeadNav } from "./MobileViewHeadNav/MobileViewHeadNav";
import { Modal } from "../../../../components/index";
import { Login } from "../../../../components/Login/login";

interface NavRefs {
  coursesRef: React.RefObject<HTMLDivElement>;
  aboutUsRef: React.RefObject<HTMLDivElement>;
  pricingRef: React.RefObject<HTMLDivElement>;
  faqRef: React.RefObject<HTMLDivElement>;
}

interface HeadNavBarProps {
  navRefs: NavRefs;
}


const HeadNavBar: React.FC<HeadNavBarProps> = ({ navRefs }) => {
  const [isActiveNav, setIsActiveNav] = useState<number>(0);
  const [ isLoginModalOpen, setIsLoginModalOpen ] = useState(false);
  const [isMobileViewOpen,setIsMobileViewOpen] = useState(window.innerWidth <= 800);
  const NavBarOptions = [
    { label: "Courses", ref: navRefs.coursesRef },
    { label: "About", ref: navRefs.aboutUsRef },
    { label: "Pricing", ref: navRefs.pricingRef },
    { label: "FAQ's", ref: navRefs.faqRef },
  ];

  const handleNavClick = (index: number, ref: React.RefObject<HTMLDivElement>) => {
    setIsActiveNav(index);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleResize = () => {
    setIsMobileViewOpen(window.innerWidth <= 800);
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="headNavBar-container">
          {
      isLoginModalOpen && (<Modal> <Login closeModal={()=> setIsLoginModalOpen(false)}  /> </Modal>)
     }

      { isMobileViewOpen  ?
      <MobileViewHeadNav isActiveNav={isActiveNav} NavBarOptions={NavBarOptions} handleNavClick ={handleNavClick}/> : <>
        <div className="webmaster-logo"><div className="webmaster-logo-container"><WebMastersLogo isDarkMode={true} /></div></div>
      <div className="headNavBar-box">
        <div className="nav-buttons-container">

          {
            NavBarOptions.map((navItem, index) => {
              return (
                <div
                  key={index}
                  className={`nav-buttons ${isActiveNav === index && "active-nav"}`}
                  onClick={() => handleNavClick(index, navItem.ref)}
                >
                  {navItem.label}
                </div>
              )
            })
          }
        </div>
        <div className="login-and-apply-button">
          <div className="login-button"
          onClick={() =>
            setIsLoginModalOpen(true)}>
            Login
          </div>
          <div className="Apply-now-button-inside-nav">
            <ApplyNowButton />
          </div>
        </div>
      </div>
      <div className="Apply-now-button">
        <ApplyNowButton />
      </div>
      </>
      }

    </div>
  );
};

export default HeadNavBar;